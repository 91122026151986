import React from 'react';
import { PageProps } from 'gatsby';

import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Navbar from '../components/Navbar';
import Main from '../components/Main';
import Footer from '../components/Footer';
import Button from '../components/Button';
import Text from '../components/Text';

const NotFoundRoute: React.FC<PageProps> = () => {
  return (
    <Layout>
      <Meta
        title="Not found"
        description="We could not find the page you are looking for"
      />
      <Navbar />
      <Main column>
        <Text as="h3">Not found ¯\\_(ツ)_/¯</Text>
        <Button as="link" url="/" variant="accent" label="Go to Homepage" />
      </Main>
      <Footer />
    </Layout>
  );
};

export default NotFoundRoute;
